import clsx from 'clsx';
import { ReactNode } from 'react';

const variants = {
  primary: 'text-white bg-indigo-600 hover:bg-indigo-700 border-transparent',
  secondary: 'border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50 border-gray-300',
  danger: 'text-white bg-red-600 hover:bg-red-700 border-transparent',
} as const;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  size: ButtonSize;
  variant?: keyof typeof variants;
}

export enum ButtonSize {
  XS = 'px-2.5 py-1.5 text-xs font-medium',
  S = 'px-3 py-2 text-sm font-medium',
  M = 'px-4 py-2 text-sm font-medium',
  L = 'px-4 py-2 text-base font-medium',
  XL = 'px-6 py-3 text-base font-medium',
}

export function Button({
  children, size, variant = 'primary', ...otherProps
}: Props) {
  return (
    <button
      type="button"
      className={clsx(
        size,
        variants[variant],
        `inline-flex gap-2 items-center rounded border focus:ring-indigo-500
       shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`,
      )}
      {...otherProps}
    >
      {children}
    </button>
  );
}

Button.Size = ButtonSize;
