import { Menu } from '@headlessui/react';
import clsx from 'clsx';

interface Props {
  text: string;
  onClick: () => void;
}

export function DropdownButton({ onClick, text }: Props) {
  return (
    <Menu.Item>
      <button
        type="button"
        onClick={onClick}
        className={clsx(
          'block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 text-left w-full',
        )}
      >
        {text}
      </button>
    </Menu.Item>
  );
}
