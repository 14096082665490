import Link from 'next/link';
import { Menu } from '@headlessui/react';
import clsx from 'clsx';

interface Props {
  href: string;
  text: string;
}

export function DropdownLink({ href, text }: Props) {
  return (
    <Menu.Item>
      <Link
        href={href}
        className={clsx(
          'block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700',
        )}
      >
        {text}
      </Link>
    </Menu.Item>
  );
}
