import { Menu } from '@headlessui/react';

interface Props {
  groups: React.ReactNode[][]
  children: React.ReactNode;
}

export function Dropdown({
  groups, children,
}: Props) {
  return (
    <Menu>
      <Menu.Button>{children}</Menu.Button>
      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg">
        {groups.map((group) => {
          if (!group.length) return null;
          return <div className="p-2" key={group.toString()}>{group.map((g) => g)}</div>;
        })}
      </Menu.Items>
    </Menu>
  );
}
