import { Disclosure } from '@headlessui/react';
import { signIn, signOut, useSession } from 'next-auth/react';
import { Button, ButtonSize } from '../button/Button';

const logout = () => {
  signOut();
};

const userNavigation = [
  { name: 'Teams', href: '/Teams', as: 'a' },
  { name: 'Integrations', href: '/integrations', as: 'a' },
  {
    name: 'Sign out', onClick: logout, as: 'button', type: 'button',
  },
] as const;

export default function UserMobileDropdown() {
  const { data: session, status } = useSession();

  if (status === 'loading') {
    return null;
  }

  if (status === 'unauthenticated') {
    return <Button size={ButtonSize.L} onClick={() => signIn()}>Login</Button>;
  }

  const initials = session?.user?.name?.split(' ').map((n) => n[0]).join('');

  return (
    <div className="border-t border-gray-700 pt-4 pb-3">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          {session?.user?.image ? (
            <img
              src={session?.user?.image || ''}
              referrerPolicy="no-referrer"
              alt="Avatar"
              className="h-10 w-10 rounded-full"
            />
          ) : (
            <div className="flex w-10 h-10 rounded-full ring-2 ring-white bg-slate-600 text-white items-center justify-center">
              <span className="text-l">{initials}</span>
            </div>
          )}
          {' '}

        </div>
        <div className="ml-3">
          <div className="text-base font-medium leading-none text-white">{session?.user.name}</div>
        </div>
      </div>
      <div className="mt-3 space-y-1 px-2">
        {userNavigation.map((item) => (
          <Disclosure.Button
            key={item.name}
            {...item}
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white cursor-pointer"
          >
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
    </div>
  );
}
