import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { Toaster } from 'sonner';
import clsx from 'clsx';
import { Disclosure } from '@headlessui/react';
import UserDropdown from 'src/components/shared/menu/UserDropdown';
import { MdClose, MdMenu } from 'react-icons/md';
import Link from 'next/link';
import UserMobileDropdown from 'src/components/shared/menu/UserMobileDropdown';
import { useRouter } from 'next/router';
import { useIsEmbed } from 'src/hooks/useIsEmbed';
import { SWRConfig } from 'swr';
import { NextIntlProvider } from 'next-intl';
import { trpc } from 'src/utils/trpc';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();
  const isEmbeded = useIsEmbed();
  const hasOnlyOneTeam = session?.user?.teams.length === 1;
  const firstTeam = session?.user?.teams[0];

  const teamId = router.query.teamId as string;

  if (isEmbeded) {
    return (
      <NextIntlProvider messages={pageProps.messages}>
        <SWRConfig value={{ revalidateOnFocus: false }}>
          <SessionProvider session={session}>
            <Toaster position="top-center" richColors />
            <Component {...pageProps} />
          </SessionProvider>
        </SWRConfig>
      </NextIntlProvider>
    );
  }

  const navigation = [
    { name: 'Dashboard', href: `/teams/${teamId || firstTeam}/dashboard` },
    { name: 'Coach', href: `/teams/${teamId || firstTeam}/evolution` },
    { name: 'Poker', href: `/teams/${teamId || firstTeam}/poker` },
  ];

  return (
    <NextIntlProvider messages={pageProps.messages}>

      <SWRConfig value={{ revalidateOnFocus: false }}>
        <SessionProvider session={session}>
          <Toaster position="top-right" richColors />
          <div className="h-full bg-gray-100">
            <Disclosure as="nav" className="bg-gray-800">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <img
                            className="h-5"
                            src="/logo.svg"
                            alt="Frizb.ee"
                          />
                        </div>
                        <div className="hidden md:block">
                          <div className="ml-10 flex items-baseline space-x-4">
                            {(hasOnlyOneTeam || teamId) && navigation.map((item) => (
                              <Link
                                key={item.name}
                                href={item.href}
                                className={clsx(
                                  router.pathname.startsWith(item.href)
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  'rounded-md px-3 py-2 text-sm font-medium',
                                )}
                                aria-current={router.pathname.startsWith(item.href) ? 'page' : undefined}
                              >
                                {item.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                          {/* Profile dropdown */}
                          <UserDropdown />
                        </div>
                      </div>
                      <div className="-mr-2 flex md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button
                          className={`inline-flex items-center justify-center rounded-md
                        bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white
                        focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800`}
                        >
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <MdClose className="block h-6 w-6" aria-hidden="true" />
                          ) : (
                            <MdMenu className="block h-6 w-6" aria-hidden="true" />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  {/* MOBILE */}
                  <Disclosure.Panel className="md:hidden">
                    <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                      {navigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={clsx(
                            router.pathname.startsWith(item.href)
                              ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'block rounded-md px-3 py-2 text-base font-medium',
                          )}
                          aria-current={router.pathname.startsWith(item.href) ? 'page' : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                    <UserMobileDropdown />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Component {...pageProps} />
          </div>
        </SessionProvider>
      </SWRConfig>
    </NextIntlProvider>
  );
}

export default trpc.withTRPC(MyApp);
