import { signIn, signOut, useSession } from 'next-auth/react';
import { Button, ButtonSize } from '../button/Button';
import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownButton } from '../Dropdown/DropdownButton';
import { DropdownLink } from '../Dropdown/DropdownLink';

const logout = () => {
  signOut();
};

export default function UserDropdown() {
  const { data: session, status } = useSession();

  if (status === 'loading') {
    return null;
  }

  if (status === 'unauthenticated') {
    return <Button size={ButtonSize.S} onClick={() => signIn()}>Login</Button>;
  }

  const initials = session?.user?.name?.split(' ').map((n) => n[0]).join('');

  const { teams = [] } = session?.user || {};

  return (
    <div className="dropdown dropdown-end relative">
      <Dropdown
        groups={[
          teams.map((team) => (<DropdownLink href={`/teams/${team.id}/dashboard`} text={team.name} key={team.id} />)),
          [
            <DropdownLink href="/teams" text="Teams" key="teams" />,
            <DropdownLink href="/integrations" text="Integrations" key="integrations" />,
            <DropdownButton onClick={logout} text="Logout" key="logout" />,
          ],
        ]}
      >
        {session?.user?.image ? (
          <img
            src={session?.user?.image || ''}
            referrerPolicy="no-referrer"
            alt="Avatar"
            className="inline-block w-8 h-8 rounded-full ring-2 ring-inset ring-white overflow-hidden"
          />
        ) : (
          <div className="flex w-8 h-8 rounded-full overflow-hidden ring-inset ring-2 ring-white bg-slate-600 text-white items-center justify-center">
            <span className="text-l">{initials}</span>
          </div>
        )}
      </Dropdown>
    </div>
  );
}
